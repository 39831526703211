import {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import useApiState, { api } from "../../../hooks/use-api-state";
import useInput from "../../../hooks/use-input";
import { IUserInfo } from "../../../messages/IAppInfo";
import { hasPermission } from "../../common/ProtectedRoute";
import { IGetCohortByIdResponse } from "../../../messages/IGetCohortByIdResponse";
import { IGetCohortMemberListResponse } from "../../../messages/IGetCohortMemberListResponse";
import { IGetCohortMemberRegistrationStatusResponse } from "../../../messages/IGetCohortMemberRegistrationStatusResponse";
import ErrorNotification from "../../common/ErrorNotification";
import Loading from "../../common/Loading";
import styles from "./CohortMembers.module.css";
import VerticalLayout from "../../common/VerticalLayout";

interface IMemberCheckedList {
  numberOfCheckedItems: number,
  checkedMembers: boolean[],
}

type CohortMemberRegistrationStatuses = { [email: string]: IGetCohortMemberRegistrationStatusResponse };

export default function CohortMembers(props: {
  userInfo: IUserInfo;
  cohort: IGetCohortByIdResponse;
}): ReactElement {
  const [getCohortMemberListApiState, setGetCohortMemberListApiState] = useApiState(true);
  const [getMemberRegistrationStatusApiState, setGetMemberRegistrationStatusApiState] = useApiState(false);
  const [generateCertificateApiState, setGenerateCertificateApiState] = useApiState(false);
  const [sendRegistrationEmailApiState, setSendRegistrationEmailApiState] = useApiState(false);
  const [sendEvalformEmailApiState, setSendEvalformEmailApiState] = useApiState(false);
  const [sendCertificateEmailApiState, setSendCertificateEmailApiState] = useApiState(false);
  const [sendAdultTrainingHuInfoEmailApiState, setSendAdultTrainingHuInfoEmailApiState] = useApiState(false);
  const [addMemberApiState, setAddMemberApiState] = useApiState(false);
  const [getCertificateLinkForUserApiState, setGetCertificateLinkForUserApiState] = useApiState(false);
  const [memberList, setMemberList] = useState<IGetCohortMemberListResponse | null>(null);
  const [checkedMemberList, setCheckedMemberList] = useState<IMemberCheckedList | null>(null);
  const [, setCohortMemberRegistrationStatuses] = useState<CohortMemberRegistrationStatuses | null>(null);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string | null>(null);
  const [selectedUserCertificateLink, setSelectedUserCertificateLink] = useState<string | null>(null);

  const emailInput = useInput(
    "",
    (value: string) => {
      return value.match(".+@.+\\..+") !== null;
    },
    "cy__err"
  );

  const emailInputErrorClass = emailInput.hasError ? "cy__err" : "";

  useEffect(() => {
    setMemberList(null);
    setCheckedMemberList(null);

    api
      .getCohortMemberList(
        setGetCohortMemberListApiState,
        props.cohort.id)
      .then((memberList: IGetCohortMemberListResponse) => {
        setMemberList(memberList);
        setCheckedMemberList({
          numberOfCheckedItems: 0,
          checkedMembers: memberList.members.map((email: string) => false)
        });
      });

    setSelectedUserEmail(null);
  }, [props.cohort.id, setGetCohortMemberListApiState]);

  async function submitHandler(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      props.cohort.membercount = await api.addCohortMember(
        setAddMemberApiState,
        props.cohort.id,
        emailInput.value);
      emailInput.reset("");

      let memberList = await api.getCohortMemberList(
        setGetCohortMemberListApiState,
        props.cohort.id
      );

      setMemberList(memberList);
      setCheckedMemberList({
        numberOfCheckedItems: 0,
        checkedMembers: memberList.members.map((email: string) => false)
      });
    } catch (e: any) {
      setMemberList(null);
      setCheckedMemberList(null);
    }
  }

  async function userSelectedHandler(email: string) {
    setSelectedUserEmail(email);

    try {
      const certificateLink =
        await api.getCertificateLinkForUser(
          setGetCertificateLinkForUserApiState,
          props.cohort.id,
          email
        );

      setSelectedUserCertificateLink(certificateLink?.link);
    } catch (e: any) { }
  }


  function resetAction(memberList: IGetCohortMemberListResponse) {
    for (let i = 0; i < memberList.members.length; ++i) {
      memberList.data[i].action_accomplished = false;
      memberList.data[i].action_errored = false;
    }
  }


  async function generateCertificateForCheckedUsers() {
    if (memberList !== null && checkedMemberList !== null) {
      resetAction(memberList);
      const initialNumberOfCheckedItems = checkedMemberList.numberOfCheckedItems;
      var selectEmail = null;
      for (let i = 0; i < memberList.members.length; ++i) {
        if (checkedMemberList.checkedMembers[i]) {
          const email = memberList.members[i];
          try {
            const response =
              await api.generateCertificateForUser(
                setGenerateCertificateApiState,
                props.cohort.id,
                email
              );
            if (response.status === "ok") {
              memberList.data[i].certificate_of_attendance_generated = true;
              memberList.data[i].action_accomplished = true;
            } else {
              memberList.data[i].action_errored = true;
            }
            if (initialNumberOfCheckedItems === 1 || email === selectedUserEmail) {
              selectEmail = email;
            }
          } catch (e: any) { }
        }
      }
      if (selectEmail) { //(Re)select to refresh the certificate on the screen
        userSelectedHandler(selectEmail);
      }
    }
  }


  async function sendRegistrationEmail() {
    if (memberList !== null && checkedMemberList !== null) {
      resetAction(memberList);
      let noneChecked = (checkedMemberList.numberOfCheckedItems === 0);

      let to_poll = [];
      for (let i = 0; i < memberList.members.length; ++i)
        if (noneChecked || checkedMemberList.checkedMembers[i])
          to_poll.push(memberList.data[i].user_id)

      let response = await api.pollRegistrationEmail(
        setSendRegistrationEmailApiState,
        to_poll,
        props.cohort.id,
        !noneChecked
      );

      const send_to = response.sending_to;
      let send_count = 0;
      if (send_to.length > 0) {
        let sendlist = "";
        for (let i = 0; i < memberList.members.length; ++i) {
          const token: string = memberList.data[i].user_id;
          if (send_to.includes(token)) {
            send_count++;
            sendlist = sendlist + "-" + memberList.members[i] + "\n";
          }
        }

        /* TODO: most probably React has a better modal for this */
        if (window.confirm(`Do you really want to (re)send registration email to the following ${send_count} users?\n` + sendlist)) {
          for (let i = 0; i < memberList.members.length; ++i) {
            const user_id: string = memberList.data[i].user_id;
            if (send_to.includes(user_id)) {
              try {
                const response =
                  await api.sendRegistrationEmail(
                    setSendRegistrationEmailApiState,
                    user_id,
                    props.cohort.id,
                    !noneChecked
                  );
                if (response.status === "registration_sent_ok") {
                  memberList.data[i].sent_registration_email = true;
                  memberList.data[i].action_accomplished = true;
                }
                else if (response.status === "new_cohort_sent_ok") {
                  memberList.data[i].sent_new_cohort_email = true;
                  memberList.data[i].action_accomplished = true;
                }
                else if (response.status === "already_registered") {
                  memberList.data[i].registered = true;
                }
                else if (response.status === "error") {
                  addMemberApiState.errorMessage = "Error occurred";
                  memberList.data[i].action_errored = true;
                }
                if (response.registration_token) {
                  memberList.data[i].registration_token = response.registration_token;
                }
              } catch (e: any) { }
            }
          }
        }
      }
    }
  }


  async function sendEvalformEmail() {
    if (memberList !== null && checkedMemberList !== null) {
      resetAction(memberList);
      let noneChecked = (checkedMemberList.numberOfCheckedItems === 0);

      let usersIds = [];
      for (let i = 0; i < memberList.members.length; ++i)
        if (noneChecked || checkedMemberList.checkedMembers[i])
          usersIds.push(memberList.data[i].user_id)

      let response = await api.pollEvalformEmail(
        setSendEvalformEmailApiState,
        props.cohort.id,
        usersIds,
        !noneChecked,
      );

      const statuses = response.statuses;
      if (Object.keys(statuses).length > 0) {
        let sendlist = "";
        let resendlist = "";
        let send_count = 0;
        let resend_count = 0;
        let send_to = [];
        for (let i = 0; i < memberList.members.length; ++i) {
          const user_id = memberList.data[i].user_id;
          const status = statuses[user_id] || null;
          if (status != null) {
            if (status === "ok") {
              send_count++;
              send_to.push(user_id);
              sendlist = sendlist + "- " + memberList.members[i] + "\n";
            }
            else if (status === "already_sent") {
              resend_count++;
              send_to.push(user_id);
              resendlist = resendlist + "- " + memberList.members[i] + "\n";
            }
          }
        }

        if ((send_count + resend_count) === 0) {
          alert(`No users to send emails to.`);
          return;
        }

        let modalMessage = "";
        if (send_count > 0)
          modalMessage = modalMessage + `The evaluation form email will be sent to the following ${send_count} users:\n${sendlist}`;
        if (resend_count > 0)
          modalMessage = modalMessage + `\nThe evaluation form REMAINDER email will be sent to the following ${resend_count} users:\n${resendlist}`;
        modalMessage = modalMessage + `\nDo you want to proceed?`;
        //TODO: most probably React has a better modal for this */
        if (window.confirm(modalMessage)) {
          const response = await api.sendEvalformEmails(
            setSendEvalformEmailApiState,
            props.cohort.id,
            send_to
          );
          const statuses = response.statuses;
          for (let i = 0; i < memberList.members.length; ++i) {
            const user_id = memberList.data[i].user_id;
            const status = statuses[user_id] || null;
            if (status === "sent_ok") {
              memberList.data[i].evalform_sent = true;
              memberList.data[i].action_accomplished = true;
            }
            else if (status !== null) {
              memberList.data[i].action_errored = true;
            }
          }
        }
      }
    }
  }



  async function sendCertificateEmail() {
    if (memberList !== null && checkedMemberList !== null) {
      resetAction(memberList);
      let noneChecked = (checkedMemberList.numberOfCheckedItems === 0);

      let usersIds = [];
      for (let i = 0; i < memberList.members.length; ++i)
        if (noneChecked || checkedMemberList.checkedMembers[i])
          usersIds.push(memberList.data[i].user_id)

      let response = await api.pollCertificateEmail(
        setSendCertificateEmailApiState,
        props.cohort.id,
        usersIds,
        !noneChecked,
      );

      const statuses = response.statuses;
      if (Object.keys(statuses).length > 0) {
        let sendlist = "";
        let nosendlist = "";
        let ok = 0;
        let send_to = [];
        for (let i = 0; i < memberList.members.length; ++i) {
          const user_id = memberList.data[i].user_id;
          const status = statuses[user_id] || null;
          if (status != null) {
            if (status === "ok") {
              ok++;
              send_to.push(user_id);
              sendlist = sendlist + "- " + memberList.members[i] + "\n";
            }
            else {
              nosendlist = nosendlist + "- " + memberList.members[i] + " (" + status + ")\n";
            }
          }
        }

        if (ok === 0) {
          alert(`No users to send emails to.`);
          return;
        }

        let modalMessage = `Do you really want to (re)send certificate email to the following ${ok} users?\n${sendlist}`;
        if (nosendlist !== "")
          modalMessage = modalMessage + `\nThe following users will not receive the email (see reson in brackets):\n${nosendlist}`;
        //TODO: most probably React has a better modal for this */
        if (window.confirm(modalMessage)) {
          const response = await api.sendCertificateEmails(
            setSendCertificateEmailApiState,
            props.cohort.id,
            send_to
          );
          const statuses = response.statuses;
          for (let i = 0; i < memberList.members.length; ++i) {
            const user_id = memberList.data[i].user_id;
            const status = statuses[user_id] || null;
            if (status === "sent_ok") {
              memberList.data[i].certificate_of_attendance_sent = true;
              memberList.data[i].action_accomplished = true;
            }
            else if (status !== null) {
              memberList.data[i].action_errored = true;
            }
          }
        }
      }
    }
  }


  async function sendAdultTrainingHuInfoEmail() {
    if (memberList !== null && checkedMemberList !== null) {
      resetAction(memberList);
      let noneChecked = (checkedMemberList.numberOfCheckedItems === 0);

      let usersIds = [];
      for (let i = 0; i < memberList.members.length; ++i)
        if (noneChecked || checkedMemberList.checkedMembers[i])
          usersIds.push(memberList.data[i].user_id)

      let response = await api.pollAdultTrainingHuInfoEmail(
        setSendAdultTrainingHuInfoEmailApiState,
        props.cohort.id,
        usersIds,
        !noneChecked,
      );

      const statuses = response.statuses;
      if (Object.keys(statuses).length > 0) {
        let sendlist = "";
        let nosendlist = "";
        let ok = 0;
        let send_to = [];
        for (let i = 0; i < memberList.members.length; ++i) {
          const user_id = memberList.data[i].user_id;
          const status = statuses[user_id] || null;
          if (status != null) {
            if (status === "ok") {
              ok++;
              send_to.push(user_id);
              sendlist = sendlist + "- " + memberList.members[i] + "\n";
            }
            else {
              nosendlist = nosendlist + "- " + memberList.members[i] + " (" + status + ")\n";
            }
          }
        }

        if (ok === 0) {
          alert(`No users to send emails to.`);
          return;
        }

        let modalMessage = `Do you really want to (re)send adult education info gathering email to the following ${ok} users?\n${sendlist}`;
        if (nosendlist !== "")
          modalMessage = modalMessage + `\nThe following users will not receive the email (see reson in brackets):\n${nosendlist}`;
        //TODO: most probably React has a better modal for this */
        if (window.confirm(modalMessage)) {
          const response = await api.sendAdultTrainingHuInfoEmail(
            setSendAdultTrainingHuInfoEmailApiState,
            props.cohort.id,
            send_to
          );
          const statuses = response.statuses;
          for (let i = 0; i < memberList.members.length; ++i) {
            const user_id = memberList.data[i].user_id;
            const status = statuses[user_id] || null;
            if (status === "sent_ok") {
              memberList.data[i].sent_adult_training_hu_info = true;
              memberList.data[i].action_accomplished = true;
            }
            else if (status !== null) {
              memberList.data[i].action_errored = true;
            }
          }
        }
      }
    }
  }


  async function checkMembersRegistration(
    event: MouseEvent<HTMLButtonElement>,
  ) {
    if (memberList !== null && checkedMemberList !== null) {
      resetAction(memberList);
      for (let i = 0; i < memberList.members.length; i++) {
        if (checkedMemberList.checkedMembers[i]) {
          const email = memberList.members[i];

          try {
            let cohortMemberRegistrationStatus =
              await api.getCohortMemberRegistrationStatus(
                setGetMemberRegistrationStatusApiState,
                email,
                props.cohort.id,
              );

            setCohortMemberRegistrationStatuses((state: CohortMemberRegistrationStatuses | null) => {
              if (state === null) {
                state = {};
              }

              let ret = { ...state };
              ret[email] = cohortMemberRegistrationStatus;
              //Update memberList.data with the received information
              // TODO: MAT-366: CohortMembers.tsx memberList should not be written directly
              memberList.data[i].registered = cohortMemberRegistrationStatus.registrationOk;
              memberList.data[i].has_lmodule_data = true;
              memberList.data[i].lmodules_ok = cohortMemberRegistrationStatus.lmodulesOk;
              memberList.data[i].attendant_version_ok = cohortMemberRegistrationStatus.sergeantversionOk;
              return ret;
            });
          } catch (e: any) { }
        }
      }
    }
  }


  function checkboxChangeHandler(index: number, e: ChangeEvent<HTMLInputElement>) {
    setCheckedMemberList((state: IMemberCheckedList | null) => {
      if (state !== null) {
        let ret = {
          numberOfCheckedItems: state.numberOfCheckedItems,
          checkedMembers: [...state.checkedMembers],
        };

        ret.checkedMembers[index] = !ret.checkedMembers[index];
        if (ret.checkedMembers[index]) {
          ret.numberOfCheckedItems++;
        } else {
          ret.numberOfCheckedItems--;
        }

        return ret;
      } else {
        return null;
      }
    });
  }

  function selectAllClickHandler(e: MouseEvent<HTMLButtonElement>) {
    setCheckedMemberList((state: IMemberCheckedList | null) => {
      if (state !== null) {
        if (state.numberOfCheckedItems === 0) {
          return {
            numberOfCheckedItems: state.checkedMembers.length,
            checkedMembers: state.checkedMembers.map((isselected: boolean) => {
              return true;
            })
          }
        } else {
          return {
            numberOfCheckedItems: 0,
            checkedMembers: state.checkedMembers.map((isselected: boolean) => {
              return false;
            })
          }
        }
      } else {
        return null;
      }
    });
  }


  function getRegistrationLink(index: number): string {
    const member = memberList?.data[index];
    var link: string = `https://cydrillsergeant.com/admin/register?registration-token=${member?.registration_token}`;
    return link;
  }


  return (
    <>
      <div className="cy__form form__certificates">
        <VerticalLayout
          keyForStorage="cohortMembers"
          topPanel={
            <>
              <div className={`members ${styles.top__panel}`}>
                <div className="buttons">
                  <button
                    type="submit"
                    className={`btn secondary`}
                    onClick={selectAllClickHandler}
                    disabled={checkedMemberList === null || checkedMemberList.checkedMembers.length === 0}
                  >
                    {checkedMemberList === null || checkedMemberList.numberOfCheckedItems === 0 ? <>Select all</> : <>Select none</>}
                  </button>
                </div>

                <h3>Members ({props.cohort.membercount})</h3>
                <ul className="memberlist">
                  <Loading isLoading={getCohortMemberListApiState.isLoading}>
                    {!!getCohortMemberListApiState.errorMessage ? (
                      <ErrorNotification
                        errorMessage={getCohortMemberListApiState.errorMessage}
                      />
                    ) : (
                      <>
                        {memberList?.members.map((email: string, index) => {
                          return (
                            <li
                              className={selectedUserEmail === email ? "active" : ""}
                              key={email}
                            >
                              <input
                                className={styles.membercheckbox}
                                type="checkbox"
                                checked={checkedMemberList?.checkedMembers[index]}
                                onChange={(e) => checkboxChangeHandler(index, e)}
                              >
                              </input>

                              <>
                                {memberList?.data[index].registered ? (
                                  <>
                                    <img
                                      src="/icons/CydrillSergeant_favicon.png"
                                      className={`${styles.membericon} ${styles.membericon_registered}`}
                                      title="The user is registered."
                                      alt=""
                                    />
                                    <span className={styles.attendant_version}>v{memberList?.data[index].attendant_version ?? ""}</span>
                                    {memberList?.data[index].sent_new_cohort_email === true && (
                                      <img
                                        src="/icons/email_40x40_2.png"
                                        className={styles.membericon}
                                        title="The new cohort assignment email has been sent."
                                        alt=""
                                      />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/* If sent_cohort_email is not null, we take that; only if it's null, we take into account sent_registration_email. */}
                                    {memberList?.data[index].sent_registration_email === true && (
                                      <a href={`mailto:${memberList?.data[index].email}?subject=Resending your registration link for CydrillSergeant.com from my account&body=Dear Participant,%0d%0a%0d%0aJust a quick resend of your registration link - please find it here:%0d%0a${getRegistrationLink(index)}%0d%0a%0d%0aBest regards`}>
                                        <img
                                          src="/icons/email_40x40_2.png"
                                          className={styles.membericon}
                                          title={decodeURI("The registration email has been sent.%0AClick here to resend the registration link by mailto: from your personal email account.")}
                                          alt=""
                                        />
                                      </a>
                                    )}
                                  </>
                                )}
                              </>

                              <>
                                {memberList?.data[index].registered && memberList?.data[index].has_lmodule_data ? (
                                  <>
                                    <>
                                      {memberList?.data[index].lmodules_ok &&
                                        memberList?.data[index].attendant_version_ok ? (
                                        <img
                                          src="/icons/testing_A_40x40_2.png"
                                          className={styles.membericon}
                                          title="The sergeant version and the learning module list is is correct."
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src="/icons/error_40x40.png"
                                          className={styles.membericon}
                                          title={
                                            !memberList?.data[index].attendant_version_ok
                                              ? "Sergeant version is not set correctly. "
                                              : "" +
                                                !memberList?.data[index].lmodules_ok
                                                ? "Learning module list is not correct. "
                                                : ""
                                          }
                                          alt=""
                                        />
                                      )}
                                    </>
                                  </>
                                ) : null}
                              </>

                              <>
                                {memberList?.data[index].attendant_logged_in && (
                                  <img
                                    src="/icons/cydrill_attendant_favicon.png"
                                    className={styles.membericon}
                                    title="The user successfully logged in from Attendant."
                                    alt=""
                                  />)}
                              </>

                              {memberList?.data[index].fullname ? (
                                <span onClick={() => userSelectedHandler(email)}>
                                  {email} ({memberList?.data[index].fullname})
                                </span>
                              ) : (
                                <span onClick={() => userSelectedHandler(email)}>
                                  {email}
                                </span>
                              )}

                              <img
                                src="/icons/copy.svg"
                                className={styles.membericon}
                                title={decodeURI("Copy email address to clipboard.")}
                                alt=""
                                onClick={() => navigator.clipboard.writeText(email)}
                                style={{cursor:'pointer'}}
                              />

                              {memberList?.data[index].user_lmodules &&
                                <b className={styles.user_lmodules}>
                                  [{memberList?.data[index].user_lmodules.join(",")}]
                                </b>
                              }

                              {memberList?.data[index].active_since && (
                                <span className={styles.memberactivation}>{memberList?.data[index].active_since}</span>
                              )}
                              {memberList?.data[index].active_until && (
                                <span className={styles.memberactivation}>- {memberList?.data[index].active_until}</span>
                              )}

                              <>
                                {memberList?.data[index].evalform_sent && (
                                  <a>
                                    <img
                                      src="/icons/rule_of_thumb_40x40.png"
                                      className={styles.membericon_after}
                                      title={decodeURI("The course evaluation form has been sent to the user.%0AClick here to resend a reminder from your personal email account.")}
                                      alt=""
                                    />
                                  </a>
                                )}
                              </>

                              <>
                                {memberList?.data[index].certificate_of_attendance_generated && (
                                  <img
                                    src="/icons/gamification_40x40.png"
                                    className={styles.membericon_after}
                                    title="The user has a Certificate of Attendance."
                                    alt=""
                                  />)}
                              </>
                              <>
                                {memberList?.data[index].certificate_of_attendance_sent && (
                                  <a>
                                    <img
                                      src="/icons/email_40x40.png"
                                      className={styles.membericon}
                                      title={decodeURI("The Certificate of Attendance has been sent by email.%0AClick here to resend the link from your personal email account.")}
                                      alt=""
                                    />
                                  </a>
                                )}
                              </>

                              <>
                                {props.cohort.countrycode === "HU" && memberList?.data[index].sent_adult_training_hu_info && (
                                  <img
                                    src="/icons/homework_40x40.png"
                                    className={styles.membericon_after}
                                    title="The information gathering for the adult training info (HU) has been sent by email."
                                    alt=""
                                  />)}
                              </>

                              <>
                                {memberList?.data[index].action_accomplished && (
                                  <span>&nbsp;
                                    <img
                                      src="/icons/OK_40x40_2.png"
                                      className={styles.membericon_after}
                                      alt=""
                                    />
                                  </span>)}
                              </>
                              <>
                                {memberList?.data[index].action_errored && (
                                  <span>&nbsp;
                                    <img
                                      src="/icons/error_40x40.png"
                                      className={styles.membericon_after}
                                      alt=""
                                    />
                                  </span>)}
                              </>

                            </li>
                          );
                        })}
                      </>
                    )}
                  </Loading>
                </ul>
                <div className="divider"></div>

                {!!hasPermission(props.userInfo, [{ permission: "cohort_write", resource: props.cohort.id }]) ? (
                  <div>
                    <form onSubmit={submitHandler}>
                      <div className="cy__flex_container">
                        <div className="cy__flex_row cy__flex_gap_15px">
                          <div className="cy__flex_container">
                            <input
                              className={`${emailInputErrorClass}`}
                              value={emailInput.value}
                              onChange={emailInput.valueChangeHandler}
                              onBlur={emailInput.inputBlurHandler}
                              type="email"
                              id="email"
                              placeholder="member@e.mail"
                              required
                            />
                          </div>
                          <div>
                            <Loading isLoading={addMemberApiState.isLoading}>
                              <button
                                type="submit"
                                className={`btn secondary wide ${emailInput.isValid ? "" : "disabled"}`}
                              >
                                Add user
                              </button>
                            </Loading>
                          </div>
                          <div>
                            {!!addMemberApiState.errorMessage && (
                              <ErrorNotification
                                errorMessage={addMemberApiState.errorMessage}
                              ></ErrorNotification>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : null}
              </div>
            </>
          }
          bottomPanel={
            <>
              <div className={styles.bottom__panel}>
                {hasPermission(props.userInfo, [
                  { permission: "cohort_write", resource: props.cohort.id }
                ]) && (
                    <div className="buttons">
                      <Loading isLoading={sendRegistrationEmailApiState.isLoading}>
                        <ErrorNotification
                          errorMessage={sendRegistrationEmailApiState.errorMessage}
                        />
                        <button
                          onClick={sendRegistrationEmail}
                          className="btn primary wide"
                          title={checkedMemberList != null && checkedMemberList.numberOfCheckedItems === 0 ?
                            "Send registration emails to those users who did not receive it yet." :
                            "Refresh the registration token of the selected users and send an email to them (even if already sent)."}
                        >
                          {checkedMemberList != null && checkedMemberList.numberOfCheckedItems === 0 ?
                            "Send registration emails" :
                            "Send registration email to selected users"}
                        </button>
                      </Loading>

                      {props.cohort.countrycode === "HU" && (
                        <Loading isLoading={sendAdultTrainingHuInfoEmailApiState.isLoading}>
                          <ErrorNotification
                            errorMessage={sendAdultTrainingHuInfoEmailApiState.errorMessage}
                          />
                          <button
                            onClick={sendAdultTrainingHuInfoEmail}
                            className="btn primary wide"
                            title={checkedMemberList != null && checkedMemberList.numberOfCheckedItems === 0 ?
                              "Send adult training (HU) info gathering by email to all users." :
                              "Send adult training (HU) info gathering by email to selected users (even if already sent)."}
                          >
                            {checkedMemberList != null && checkedMemberList.numberOfCheckedItems === 0 ?
                              "Send adult training (HU) emails" :
                              "Send adult training (HU) email to selected users"}
                          </button>
                        </Loading>
                      )}

                    </div>
                  )}

                {/*Eval form can be sent by even the trainer (hence the cohort_read) */}
                {hasPermission(props.userInfo, [
                  { permission: "cohort_read", resource: props.cohort.id }
                ]) ? (
                  <div className="buttons">

                    <Loading isLoading={sendEvalformEmailApiState.isLoading}>
                      <ErrorNotification
                        errorMessage={sendEvalformEmailApiState.errorMessage}
                      />
                      <button
                        onClick={sendEvalformEmail}
                        className="btn primary wide"
                        title={checkedMemberList != null && checkedMemberList.numberOfCheckedItems === 0 ?
                          "Send course evaluation form by email to only those users who did not receive it yet." :
                          "Send course evaluation form by email to selected users (even if already sent)."}
                      >
                        {checkedMemberList != null && checkedMemberList.numberOfCheckedItems === 0 ?
                          "Send course evaluation form by email" :
                          "Send course evaluation form by email to selected users"}
                      </button>
                    </Loading>

                  </div>
                ) : null}

                {hasPermission(props.userInfo, [
                  { permission: "cohort_write", resource: props.cohort.id }
                ]) ? (
                  <div className="buttons">
                    <Loading isLoading={generateCertificateApiState.isLoading}>
                      <ErrorNotification
                        errorMessage={generateCertificateApiState.errorMessage}
                      />
                      <button
                        onClick={generateCertificateForCheckedUsers}
                        className="btn primary wide"
                        disabled={checkedMemberList?.numberOfCheckedItems === 0}
                      >
                        Generate certificate for selected users
                      </button>
                    </Loading>

                    <Loading isLoading={sendCertificateEmailApiState.isLoading}>
                      <ErrorNotification
                        errorMessage={sendCertificateEmailApiState.errorMessage}
                      />
                      <button
                        onClick={sendCertificateEmail}
                        className="btn primary wide"
                        title={checkedMemberList != null && checkedMemberList.numberOfCheckedItems === 0 ?
                          "Send certificate by email to only those users who did not receive it yet." :
                          "Send certificate by email to selected users (even if already sent)."}
                      >
                        {checkedMemberList != null && checkedMemberList.numberOfCheckedItems === 0 ?
                          "Send certificates by email" :
                          "Send certificates by email to selected users"}
                      </button>
                    </Loading>

                  </div>
                ) : null}

                {hasPermission(props.userInfo, [
                  { permission: "cohort_write", resource: props.cohort.id }
                ]) && (
                    <div className="buttons">
                      <Loading
                        isLoading={getMemberRegistrationStatusApiState.isLoading}
                      >
                        {getMemberRegistrationStatusApiState.errorMessage !== null ? (
                          <ErrorNotification
                            errorMessage={getMemberRegistrationStatusApiState.errorMessage}
                          />
                        ) : (
                          <button
                            onClick={checkMembersRegistration}
                            className="btn primary wide"
                            disabled={checkedMemberList?.numberOfCheckedItems === 0}
                          >
                            Check learning modules of selected users
                          </button>
                        )}
                      </Loading>
                    </div>
                  )}

                {hasPermission(props.userInfo, [
                  { permission: "cohort_write", resource: props.cohort.id }
                ]) && selectedUserEmail !== null && (
                    <>
                      <Loading isLoading={getCertificateLinkForUserApiState.isLoading}>
                        {getCertificateLinkForUserApiState.errorMessage !== null ? (
                          <ErrorNotification
                            errorMessage={getCertificateLinkForUserApiState.errorMessage}
                          />
                        ) : (
                          <>
                            {selectedUserCertificateLink && (
                              <>
                                <div className="cy__link">
                                  <div className="link" id="pdflink">
                                    <a
                                      href={selectedUserCertificateLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {selectedUserCertificateLink}
                                    </a>
                                    <img
                                      src="/icons/copy.svg"
                                      className={styles.copyicon}
                                      title={decodeURI("Copy the link to clipboard.")}
                                      alt=""
                                      onClick={() => navigator.clipboard.writeText(selectedUserCertificateLink)}
                                      style={{cursor:'pointer'}}
                                    />
                                  </div>
                                 
                                </div>
                                <div className="cy__pdfpreview">
                                  <embed
                                    type="application/pdf"
                                    // frameBorder="0"
                                    // scrolling="auto"
                                    height="100%"
                                    width="100%"
                                    src={selectedUserCertificateLink}
                                  ></embed>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </Loading>
                    </>
                  )}
              </div>
            </>
          }
        />
      </div>
    </>
  );
}
